<template>
  <div class="lesson">
    <text-lesson :lesson="lesson"/>
    <video-lesson :lesson="lesson"/>
    <practical-lesson v-if="lesson && lesson.answers && lesson.answers.length" :lesson="lesson"/>
  </div>
</template>

<script>
import TextLesson from "@/app/user/lessonsCourse/components/TextLesson.vue";
import VideoLesson from "@/app/user/lessonsCourse/components/VideoLesson.vue";
import PracticalLesson from "@/app/user/lessonsCourse/components/PracticalLesson/PracticalLesson.vue";

export default {
  name: 'lesson',
  components: {TextLesson, PracticalLesson, VideoLesson},
  props: {
    lesson: { type: Object }
  }
}
</script>

<style lang="scss" scoped>
.lesson {
  //display: flex;
  //flex-direction: column;

  width: 100%;
  //height: 100%;

  padding: 24px;

  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  border-radius: 8px;

  overflow-y: scroll;
  scrollbar-width: none;
  /* полоса прокрутки (скроллбар) */
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 6px;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #e3e3e3;
  }
}
</style>