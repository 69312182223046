<template>
  <div class="practical-lesson-wrapper">
    <div class="practical-lesson-wrapper__title">Выполните задание для закрепления знаний</div>

    <div class="practical-lesson-wrapper__question">{{lesson.question}}</div>

    <one-answer v-if="!lesson.isRigthAnswersMultiple" :answers="lesson.answers"/>
    <multiple-answers v-else :answers="lesson.answers"/>
  </div>
</template>

<script>
import OneAnswer from "./OneAnswer";
import MultipleAnswers from "./MultipleAnswers";

export default {
  name: "PracticalLesson",
  props: {
    lesson: Object
  },
  components: {
    OneAnswer,
    MultipleAnswers
  },
  data() {
    return {
      selectedIds: null,
      statusAnswer: null,
    }
  },
}
</script>

<style lang="scss">
.practical-lesson-wrapper {

  &__title {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1454F2;

    margin-bottom: 16px;
  }

  &__question {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    white-space: pre-wrap;
    color: #121212;

    transition: 0.2s;

    @media screen and (max-width: 400px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

}
</style>