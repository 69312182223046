<template>
  <div class="program-lessons-wrapper">
    <div class="program-lessons-wrapper__title">Программа курса</div>
    <div class="program-lessons-wrapper__theme"
         v-for="item in themes" :key="item.id">
      <div class="program-lessons-wrapper__theme--title">
        {{ item.name }}
      </div>
      <div class="program-lessons-wrapper__lesson"
           v-for="(lesson,index) in item.lessons" :key="index"
           @click="$emit('clickLesson', lesson)">

        <div class="program-lessons-wrapper__check"
             :class="{'program-lessons-wrapper__check--passed':lesson.isCompleted}">
          <i class="el-icon-check"/>
        </div>

        <div class="program-lessons-wrapper__lesson-info">
          <div class="program-lessons-wrapper__name-lesson">{{ lesson.name }}</div>
        </div>

        <div class="program-lessons-wrapper__check-line"/>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProgramLessons",
  computed: {
    ...mapGetters(['themes']),

    selectLessonId() {
      return Number(this.$route.params.lessonId)
    }
  }
}
</script>

<style lang="scss" scoped>
.program-lessons-wrapper {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 69px;

  background: #FFFFFF;
  border-radius: 4px;

  z-index: 2;
  width: 200px;

  height: 100%;
  max-height: calc(100vh - 182px);

  overflow-y: scroll;
  scrollbar-width: none;

  padding-bottom: 24px;
  transition: 0.2s;

  @media screen and (max-width: 980px) {
    padding-left: 24px;
  }
  @media screen and (max-width: 600px) {
    max-height: calc(100vh - 145px);
    border: 1px solid #E7E7E7;
    border-left: none;
    box-sizing: border-box;
    border-radius: 8px;

    padding-left: 24px;
    padding-right: 24px;
    width: 248px;
  }
  @media screen and (max-width: 410px) {
    max-height: calc(100vh - 157px);
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    max-height: calc(100vh - 128px);

    top: 50px;
    padding-top: 20px;

    border-radius: 0;
    border: none;
  }

  /* полоса прокрутки (скроллбар) */
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #e3e3e3;
  }

  &__theme {
    margin-top: 10px;
  }

  &__title {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;
  }

  &__lesson {
    display: flex;
    align-items: flex-start;

    margin-top: 16px;
    position: relative;

    cursor: pointer;

    &:last-child {
      .program-lessons-wrapper__check-line {
        display: none;
      }
    }

    &:hover {
      background: #F8FBFF;
      border-radius: 4px;

      transition: 0.1s;

      .program-lessons-wrapper__type-lesson {
        color: #1454F2;
        transition: 0.1s;
      }
    }
  }

  &__type-lesson {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    color: #121212;

    &--active {
      color: #1454F2;
      transition: 0.1s;
    }
  }

  &__name-lesson {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    word-break: break-word;
    color: #121212;
  }

  &__check {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    min-width: 24px;
    height: 24px;

    margin-right: 12px;

    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 100%;

    z-index: 1;
    transition: 0.2s;

    .el-icon-check {
      display: none;
    }

    &--passed {
      background: #4EE1A3;
      border: 1px solid #4EE1A3;
      transition: 0.2s;

      .el-icon-check {
        display: block;
        color: #FFFFFF;
        font-size: 12px;
        transition: 0.2s;
      }
    }
  }

  &__check-line {
    z-index: 0;
    position: absolute;
    height: 100%;
    background: #C4C4C4;
    width: 0.5px;
    left: 11.8px;
    top: 21px;
  }
}
</style>