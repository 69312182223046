<template>
  <div class="lesson-footer-wrapper">
    <div class="container">
      <div class="lesson-footer" :style="{'justify-content': !prevLesson ? 'flex-end':'space-between'}">

        <div class="lesson-footer__previous" v-if="prevLesson" @click="goToLesson(prevLesson)">
          <img class="lesson-footer__icon-arrow-left"
               src="@/assets/img/user/lessons/footer/icon-arrow-left.svg"
               alt="back">
          <div class="lesson-footer__previous-main">
            <div class="lesson-footer__text">Предыдущий материал</div>
            <transition name="lesson" mode="out-in">
              <div class="lesson-footer__title-lesson" :key="lessonId">{{ prevLesson.name }}</div>
            </transition>
          </div>
        </div>

        <div class="lesson-footer__next" v-if="nextLesson" @click="goToLesson(nextLesson)">
          <div class="lesson-footer__next-main">
            <div class="lesson-footer__text">Следующий материал</div>
            <transition name="lesson" mode="out-in">
              <div class="lesson-footer__title-lesson" :key="lessonId">{{ nextLesson.name }}</div>
            </transition>
          </div>
          <img class="lesson-footer__icon-arrow-right"
               src="@/assets/img/user/lessons/footer/icon-arrow-right.svg"
               alt="next">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "LessonsFooter",
  computed: {
    ...mapGetters(['themes','menuActive']),
    lessonId() {
      return Number(this.$route.params.lessonId)
    },
    prevLesson() {
      let index = this.lessons.findIndex(lesson => lesson.id === this.lessonId)
      if (index !== -1) {
        return this.lessons[index - 1]
      }
      return null
    },
    lessons() {
      const lessons = []
      this.themes.forEach(item => {
        if (item.lessons?.length) {
          lessons.push(...item.lessons)
        }
      })
      return lessons
    },
    nextLesson() {
      let index = this.lessons.findIndex(lesson => lesson.id === this.lessonId)
      if (index !== -1) {
        return this.lessons[index + 1]
      }
      return null
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
  },
  methods: {
    ...mapMutations(['setMenuActive']),

    goToLesson(lesson) {
      //скрывать меню по клику
      if (this.windowWidth < 400 && this.menuActive)
        this.setMenuActive()

      this.$router.push({params: { lessonId: lesson.id.toString() }})
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-footer-wrapper {
  display: flex;
  align-items: center;

  width: 100%;
  height: 65px;
  background: #1D262D;

  padding: 15px 0px;
  box-sizing: border-box;
  transition: 0.2s;

  @media screen and (max-width: 980px) {
    padding: 15px 24px;
  }
}

.lesson-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__text {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    color: #FFFFFF;
  }

  &__title-lesson {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;

    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &__previous {
    display: flex;
    align-items: flex-start;
    max-width: 40%;

    cursor: pointer;

    @media screen and (max-width: 600px) {
      align-items: center;
      width: max-content;
      transition: 0.2s;
    }
  }

  &__previous-main {
    display: flex;
    flex-direction: column;

    width: 100%;

    @media screen and (max-width: 600px) {
      width: auto;
    }
  }

  &__next {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    max-width: 40%;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      align-items: center;
      width: max-content;
      transition: 0.2s;
    }
  }

  &__next-main {
    display: flex;
    flex-direction: column;
    width: 100%;

    .lesson-footer__title-lesson, .lesson-footer__text {
      text-align: right;
    }

    @media screen and (max-width: 600px) {
      width: auto;
    }
  }

  &__icon-arrow-left {
    margin-right: 14px;
    cursor: pointer;
  }

  &__icon-arrow-right {
    margin-left: 14px;
    cursor: pointer;
  }
}
</style>