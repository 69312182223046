<template>
  <div class="text-lesson-wrapper">
    <div class="text-lesson-wrapper__title">Задание</div>
    <div class="text-lesson-wrapper__content ck-content" v-html="lesson.description"></div>

    <template v-if="lesson && lesson.attachments">
      <div class="text-lesson-wrapper__material"
           v-for="(item,index) in lesson.attachments.filter(attachment => attachment.type !== 'video')" :key="index">

        <img class="text-lesson-wrapper__icon-download"
             @click="clickDownloadFile(lessonId,item)"
             src="@/assets/img/user/lessons/text-lesson/icon-download.svg"
             alt="download">

        <img v-if="typeFile(item) === 'pdf'"
             src="@/assets/img/user/lessons/text-lesson/icon-pdf.svg" alt="">
        <img v-else-if="typeFile(item) === 'doc'"
             src="@/assets/img/user/lessons/text-lesson/icon-doc.svg" alt="">
        <img v-else class="text-lesson-wrapper__icon-file"
             src="@/assets/img/user/lessons/text-lesson/icon-file.svg" alt="">

        <div class="text-lesson-wrapper__material-name">{{ item.title }}</div>
      </div>
    </template>

  </div>
</template>

<script>
import {lessonsService} from "../lessonsCourse.service";

export default {
  name: "TextLesson",
  props: {
    lesson: Object
  },
  computed: {
    courseId() {
      return this.$route.params.courseId
    },
    lessonId() {
      return this.$route.params.lessonId
    },
  },
  mounted() {
    if (!this.lesson.isCompleted && (!this.lesson.question || !this.lesson.answers)) {
      lessonsService.passedLesson(this.lesson.id)
    }
  },
  methods: {
    typeFile(attachment) {
      const title = attachment.title.toLowerCase().split('.')
      const type = title[title.length - 1]
      if (type === 'pdf')
        return 'pdf'
      else if (type === 'doc' || type === 'docx')
        return 'doc'
      else
        return 'file'
    },
    async clickDownloadFile(lessonId, attachment) {
      const file = await lessonsService.downloadFile(lessonId, attachment.id)
      const url = window.URL.createObjectURL(file)
      const fileLink = document.createElement('a')

      fileLink.href = url
      fileLink.setAttribute('download', attachment.title)
      document.body.appendChild(fileLink)
      fileLink.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.text-lesson-wrapper {

  &__title {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1454F2;

    margin-bottom: 16px;
  }

  &__content {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    white-space: pre-wrap;

    color: #121212;

    margin-bottom: 42px;
  }

  &__material {
    display: flex;
    align-items: center;

    margin-bottom: 22px;
  }

  &__material-name {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    margin-left: 12px;
  }

  &__icon-download {
    margin-right: 20px;
    cursor: pointer;
  }

  &__icon-file {
    width: 18px;
    margin-right: 3px;
    margin-left: 3px;
  }
}
</style>