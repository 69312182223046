<template>
  <div class="one-answer-wrapper__answers">
    <div class="one-answer-wrapper__answer" v-for="(item,index) in answers" :key="index">
      <el-radio v-model="selectedId"
                :class="item.id === selectedId && statusAnswer === false ? 'one-answer-wrapper__wrong-select':
                (item.id === selectedId && statusAnswer ? 'one-answer-wrapper__correct-select':'')"
                :label="item.id"
                :disabled="statusAnswer !== null">
        {{ item.text }}
      </el-radio>
    </div>

      <div v-if="statusAnswer === null"
           class="one-answer-wrapper__btn"
           @click="sendAnswer">
        <div class="one-answer-wrapper__btn-text">Ответить</div>
      </div>
      <div v-else>
        <div v-if="!statusAnswer" class="one-answer-wrapper__wrong-answer-text">К сожалению, Вы ответили неверно</div>
        <div v-else class="one-answer-wrapper__correct-answer-text">Задание выполнено успешно!</div>
        <div class="one-answer-wrapper__wrong-answer-btn" @click="refreshTests">Пройти тест заново</div>
      </div>

  </div>
</template>

<script>
import {lessonsService} from "../../lessonsCourse.service";
import {notificationsHelper} from "../../../../../helpers/notifications.helper";
import store from "@/store/index";
import {mapGetters} from "vuex";

export default {
  name: "OneAnswer",
  props: {
    answers: Array
  },
  data() {
    return {
      selectedId: null,
      statusAnswer: null,
    }
  },
  computed: {
    ...mapGetters(['lessons']),

    lessonId() {
      return Number(this.$route.params.lessonId)
    },
    isCompletedLesson() {
      return this.lessons.find(lesson => lesson.id === this.lessonId).isCompleted
    }
  },
  mounted() {
    if(this.isCompletedLesson)
      this.statusAnswer = true
  },
  methods: {
    sendAnswer() {
      if (this.selectedId) {
        lessonsService.passedTestsLesson(this.lessonId,[this.selectedId]).then(result=>{
          this.statusAnswer = result
          if(result)
            store.commit('lessons.setPassingLesson', this.lessonId)
        })
      }
      else
        notificationsHelper.warning('Выберите ответ')
    },
    refreshTests() {
      this.statusAnswer = null
      this.selectedId = null
    }
  }
}
</script>

<style lang="scss">
.one-answer-wrapper {

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;

    margin-top: 32px;
    padding: 0 42px;

    background: #407BFF;
    border-radius: 100px;

    cursor: pointer;
  }
  &__btn-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
  }

  &__answers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__answer {
    margin-bottom: 20px;

    .el-radio.is-disabled {
      cursor: default;
    }

    .el-radio {
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;


      &__label {
        padding-left: 18px;
      }

      &__input {
        width: 24px;
        height: 24px;
      }
      &__inner {
        width: 24px;
        height: 24px;

        &:after {
          width: 14px;
          height: 14px;
          background-color: #407BFF;
        }
        &:hover {
          border-color: #DCDFE6;
        }
      }

      &__input.is-disabled + span.el-radio__label {
        color: #121212;
        cursor: default;
      }
      &__input.is-checked + span.el-radio__label {
        color: #121212;
        cursor: default;
      }

      .el-radio__input.is-disabled .el-radio__inner {
        cursor: default;
      }

      .el-radio__input.is-checked .el-radio__inner {
        border-color: #DCDFE6;
        background: #FFFFFF;
      }
    }
  }

  &__wrong-select {
    span.el-radio__inner {
      border-color: #FF2C2C !important;
      background: #FF2C2C !important;

      &:before {
        font-family: element-icons;
        font-size: 12px;
        color: #FFFFFF;
        content: "\e6db";

        position: absolute;
        left: 5px;
        top: 5px;
      }

      &:after {
        display: none;
      }
    }
  }

  &__correct-select {
    .el-radio__input {
      .el-radio__inner {
        border-color: #4EE1A3 !important;
        background: #4EE1A3 !important;

        &:before {
          font-family: element-icons;
          font-size: 12px;
          color: #FFFFFF;
          content: "\e6da";

          position: absolute;
          left: 4px;
          top: 6px;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &__correct-answer-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #4EE1A3;
  }

  &__wrong-answer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 228px;
    height: 48px;

    margin-top: 32px;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;

    background: #407BFF;
    border-radius: 100px;

    cursor: pointer;
  }

  &__wrong-answer-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #FF2C2C;
  }
}
</style>