<template>
  <div class="lessons-course-wrapper" v-loading="loading">

    <div class="container" v-if="themes.length">
      <lessons-header/>
      <transition name="program-lessons">
        <program-lessons v-if="menuActive" @clickLesson="handleClickLesson"/>
      </transition>

      <div class="lessons-course-wrapper__main"
           :class="{'lessons-course-wrapper__main--shift': menuActive}">
        <transition
            v-if="lesson"
            appear
            name="lesson"
            mode="out-in">
          <lesson :lesson="lesson" :key="lesson.id"/>
        </transition>
        <div v-else v-loading="true"
             class="lessons-course-wrapper__loading"
             element-loading-spinner="el-icon-loading"/>
      </div>
    </div>
    <div v-else class="container lessons-course-wrapper__lesson-empty">
      <lessons-header/>
      <el-empty description="Уроки отсутствуют"/>
    </div>

    <lessons-footer/>
  </div>
</template>

<script>
import LessonsHeader from "./header/LessonsHeader";
import LessonsFooter from "./footer/LessonsFooter";
import ProgramLessons from "./components/ProgramLessons";
import Lesson from "@/app/user/lessonsCourse/components/Lesson.vue";

import {mapGetters, mapMutations} from "vuex";
import {lessonsService} from "./lessonsCourse.service";

export default {
  name: "LessonsCourseWrapper",
  data() {
    return {
      lesson: null,
      loading:false
    }
  },
  components: {
    Lesson,
    LessonsHeader,
    LessonsFooter,
    ProgramLessons,
  },

  async created() {
    this.loading = true
    await lessonsService.getInfoThemes(this.courseId).finally(()=> this.loading = false)
    if(this.themes.length) {
      if (this.$route.params.lessonId === 'null') {
        const theme = this.themes.find(item => item.lessons.length)
        if (theme) {
          this.lesson = theme.lessons[0]

          this.$router.push({params: {lessonId: this.lesson.id.toString()}})
        }
      } else {
        this.themes.forEach(item => {
          const lesson = item.lessons.find(lesson => lesson.id === this.lessonId)
          if (lesson) {
            this.lesson = lesson
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters(['menuActive', 'themes']),

    windowWidth() {
      return this.$store.state.windowWidth
    },
    courseId() {
      return this.$route.params.courseId
    },
    lessonId() {
      return Number(this.$route.params.lessonId)
    }
  },
  watch: {
    "$route.params.lessonId"() {
      if (this.$route.params.lessonId === 'null') {
        this.$router.push(`/course-info/${this.courseId}/program`)
      } else {
        this.themes.forEach(item => {
          const lesson = item.lessons.find(lesson => lesson.id === this.lessonId)
          if (lesson) {
            this.lesson = lesson
          }
        })
      }
    }
  },
  methods: {
    ...mapMutations(['setMenuActive']),

    handleClickLesson(lesson) {

      //скрывать меню по клику
      if (this.windowWidth < 600)
        this.setMenuActive()

      //если выбран другой урок
      if (lesson.id.toString() !== this.$route.params.lessonId) {
        this.$router.push({
          params: {
            lessonId: lesson.id.toString()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lessons-course-wrapper {
  &__main {
    display: flex;
    height: calc(100vh - 158px);

    padding-top: 18px;
    padding-bottom: 24px;

    transition: 0.3s;

    @media screen and (max-width: 980px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @media screen and (max-width: 600px) {
      height: calc(100vh - 147px);
      padding-left: 24px !important;
      transition: 0.1s;
    }
    @media screen and (max-width: 410px) {
      height: calc(100vh - 158px);
      transition: 0.1s;
    }

    &--shift {
      padding-left: 244px;
    }
  }
}
</style>

<style lang="scss">
.lessons-course-wrapper {
  &__loading {
    width: 100%;
    font-size: 22px;

    .el-loading-spinner {
      top: 40%;
    }
  }
  &__lesson-empty {
    .el-empty {
      height: calc(100vh - 116px);
      transition: 0.3s;

      &__description p {
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

      }
    }
  }

}
</style>