<template>
  <div class="multiple-answer-wrapper__answers">
    <div class="multiple-answer-wrapper__text">Выберите несколько правильных ответов</div>

    <el-checkbox-group v-model="selectedIds">
      <div class="multiple-answer-wrapper__answer" v-for="(item,index) in answers" :key="index">
        <el-checkbox
            :class="(selectedIds.find(answerId=> answerId === item.id) && statusAnswer ? 'multiple-answer-wrapper__correct-select':'')"
            :label="item.id"
            :disabled="statusAnswer !== null">
          {{ item.text }}
        </el-checkbox>
      </div>
    </el-checkbox-group>

    <div v-if="statusAnswer === null"
         class="multiple-answer-wrapper__btn"
         @click="sendAnswers">
      <div class="multiple-answer-wrapper__btn-text">Ответить</div>
    </div>
    <div v-else>
      <div v-if="!statusAnswer" class="multiple-answer-wrapper__wrong-answer-text">К сожалению, Вы ответили неверно</div>
      <div v-else class="multiple-answer-wrapper__correct-answer-text">Задание выполнено успешно!</div>
      <div class="multiple-answer-wrapper__wrong-answer-btn" @click="refreshTests">Пройти тест заново</div>
    </div>

  </div>
</template>

<script>
import {notificationsHelper} from "../../../../../helpers/notifications.helper";
import {lessonsService} from "../../lessonsCourse.service";
import {mapGetters} from "vuex";

import store from "@/store/index";

export default {
  name: "MultipleAnswers",
  props: {
    answers: Array
  },
  data() {
    return {
      selectedIds: [],
      statusAnswer: null,
    }
  },
  computed: {
    ...mapGetters(['lessons']),

    lessonId() {
      return Number(this.$route.params.lessonId)
    },
    isCompletedLesson() {
      return this.lessons.find(lesson => lesson.id === this.lessonId).isCompleted
    }
  },
  mounted() {
    if(this.isCompletedLesson)
      this.statusAnswer = true
  },
  methods: {
    sendAnswers() {
      if (this.selectedIds.length) {
        lessonsService.passedTestsLesson(this.lessonId,this.selectedIds).then(result=>{
          this.statusAnswer = result

          if(result)
            store.commit('lessons.setPassingLesson', this.lessonId)
        })
      }
      else
        notificationsHelper.warning('Выберите ответ')
    },
    refreshTests() {
      this.statusAnswer = null
      this.selectedIds = []
    }
  }
}
</script>

<style lang="scss">
.multiple-answer-wrapper {
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;

    margin-top: 32px;
    padding: 0 42px;

    background: #407BFF;
    border-radius: 100px;

    cursor: pointer;
  }
  &__btn-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
  }

  &__text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    margin-bottom: 20px;
    margin-top: 6px;
  }

  &__answers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 20px;
  }
  &__answer {
    margin-bottom: 20px;

    .el-checkbox.is-disabled {
      cursor: default;
    }

    .el-checkbox {
      display: flex;
      align-items: center;

      &__label {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #121212;

        padding-left: 18px;
      }

      &__inner {
        width: 24px;
        height: 24px;
        border-radius: 4px;

        &:hover {
          border-color: #DCDFE6;
        }

        &:after {
          height: 11px;
          left: 8px;
          position: absolute;
          top: 3px;
          width: 4px;
        }
      }

      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #121212;
      }

      .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #DCDFE6 !important;
      }

      .el-checkbox__input.is-checked.is-focus .el-checkbox__inner {
        border-color: transparent !important;
      }

      &__input.is-disabled + span.el-checkbox__label {
        color: #121212;
        cursor: default;
      }

      .el-checkbox__input.is-disabled .el-checkbox__inner {
        cursor: default;
      }

      .el-checkbox__input.is-checked .el-checkbox__inner {
        border-color: #409EFF;
        background: #409EFF;
      }

      .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #DCDFE6;
      }
    }
  }
  &__wrong-select {
    span.el-checkbox__inner {
      border-color: #FF2C2C !important;
      background: #FF2C2C !important;

      &:before {
        font-family: element-icons;
        font-size: 14px;
        color: #FFFFFF;
        content: "\e6db";

        position: absolute;
        left: 4px;
        top: 5px;
      }

      &:after {
        display: none;
      }
    }
  }
  &__correct-select {
    .el-checkbox__input {
      .el-checkbox__inner {
        border-color: #4EE1A3 !important;
        background: #4EE1A3 !important;

        &:before {
          font-family: element-icons;
          font-size: 14px;
          color: #FFFFFF;
          content: "\e6da";

          position: absolute;
          left: 3px;
          top: 5px;
        }

        &:after {
          display: none;
        }
      }
    }
  }
  &__correct-answer-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #4EE1A3;
  }
  &__wrong-answer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 228px;
    height: 48px;

    margin-top: 32px;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;

    background: #407BFF;
    border-radius: 100px;

    cursor: pointer;
  }
  &__wrong-answer-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #FF2C2C;
  }
}
</style>