<template>
  <div class="lesson-header-wrapper">
    <div class="lesson-header">

      <div class="lesson-header__left">
        <img class="lesson-header__icon-menu"
             :class="{'lesson-header__icon-menu--inactive': !menuActive}"
             @click="setMenuActive()"
             src="@/assets/img/user/lessons/header/icon-roll-up.svg"
             alt="menu">

        <transition name="lesson" mode="out-in">
          <div class="lesson-header__name-lesson" :key="nameCourse">{{ nameCourse }}</div>
        </transition>

      </div>

      <div class="lesson-header__right">
        <div class="lesson-header__right-text">Вернуться к курсу</div>
        <img class="lesson-header__icon-close"
             @click="$router.push(`/course-info/${courseId}/program`)"
             src="@/assets/img/user/lessons/header/icon-close.svg"
             alt="menu">
      </div>
    </div>
    <el-divider/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {lessonsService} from "../lessonsCourse.service";

export default {
  name: "LessonsHeader",
  data() {
    return {
      activeMenu: false,
      nameCourse: ''
    }
  },
  mounted() {
    lessonsService.getInfoCourse(this.courseId).then(course=> {
      this.nameCourse = course.name
    })
  },
  computed: {
    ...mapGetters(['menuActive', 'lessons','myCourses']),

    courseId() {
      return this.$route.params.courseId
    },
    lessonId() {
      return Number(this.$route.params.lessonId)
    }
  },
  methods: {
    ...mapMutations(['setMenuActive'])
  }
}
</script>

<style lang="scss" scoped>
.lesson-header-wrapper {
  .el-divider {
    opacity: 0;
    margin: 0;

    transition: 0.2s;

    @media screen and (max-width: 980px) {
      opacity: 1;
      display: block;
    }
  }
}

.lesson-header {
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 50px;

  @media screen and (max-width: 980px) {
    padding: 0px 24px;
    box-sizing: border-box;
  }

  &__left {
    display: flex;
    align-items: center;
    width: calc(100% - 320px);

    @media screen and (max-width: 980px) {
      width: calc(100% - 250px);
    }

    @media screen and (max-width: 600px) {
      width: calc(100% - 200px);
    }
    @media screen and (max-width: 500px) {
      width: calc(100% - 100px);
    }
    @media screen and (max-width: 400px) {
      width: calc(100% - 50px);
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__right-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  &__name-lesson {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    word-break: break-word;
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
  }

  &__icon-menu {
    margin-right: 22px;
    cursor: pointer;

    transition: 0.3s;

    &--inactive {
      transform: rotateY(180deg);
    }
  }

  &__icon-close {
    margin-left: 13px;
    cursor: pointer;
  }
}
</style>